<template>
	<section class="-content">
		<h3>{{ title }}</h3>
		<div class="d-flex mb-sm-5 mb-3">
			<slot name="icon"></slot>
			<div v-html="content" class="d-flex flex-column pt-3 content"></div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		title: { required: true, type: String},
		content: { required: true, type: String }
	}
}
</script>
